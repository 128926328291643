.App {
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content:center
}

.game_information {
display:flex;
flex-direction:column;
align-items: center;
}

.gameInfo-card {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
}

.gameInfo-details {
display: flex;
flex-direction: column;
flex: 3;
text-align: center;
}

.gameInfo-details-info {
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin:5px;
}

.game_information button {
  cursor:pointer;
}

.icon-span {
  margin:10px;
}
.thumb img {
  width: 15vw;
}